/* frontend/src/styles/notifications.css */
.notification-enter {
  opacity: 0;
  transform: scale(0.9);
}

.notification-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}

.notification-exit {
  opacity: 1;
  transform: scale(1);
}

.notification-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}

/* Custom scrollbar for the notifications dropdown */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #CBD5E0 #EDF2F7;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #EDF2F7;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #CBD5E0;
  border-radius: 3px;
}