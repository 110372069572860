
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap');
/* Custom styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.recharts-wrapper {
  margin: 0 auto;
}

.recharts-bar-rectangle {
  transition: opacity 0.3s;
}

.recharts-bar-rectangle:hover {
  opacity: 0.8;
}

/* Optional: Custom tooltip styles */
.recharts-tooltip-wrapper {
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.select__control {
  border-color: #e5e7eb !important;
  border-radius: 0.375rem !important;
  min-height: 38px !important;
}

.select__control--is-focused {
  border-color: #3b82f6 !important;
  box-shadow: 0 0 0 1px #3b82f6 !important;
}

.select__menu {
  border-radius: 0.375rem !important;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06) !important;
}

.select__option--is-focused {
  background-color: #eff6ff !important;
}

.select__option--is-selected {
  background-color: #3b82f6 !important;
}

.select__multi-value {
  background-color: #eff6ff !important;
  border-radius: 0.25rem !important;
}

.select__multi-value__label {
  color: #1e40af !important;
  font-size: 0.875rem !important;
}

.select__multi-value__remove {
  color: #1e40af !important;
}

.select__multi-value__remove:hover {
  background-color: #dbeafe !important;
  color: #1e3a8a !important;
}
/* In your CSS file */
.select__control {
  border-color: #e5e7eb !important;
}

.select__control--is-focused {
  border-color: #3b82f6 !important;
  box-shadow: 0 0 0 1px #3b82f6 !important;
}

.select__option--is-focused {
  background-color: #eff6ff !important;
}

.select__option--is-selected {
  background-color: #3b82f6 !important;
}
/* src/styles/adminOverview.css */
.recharts-wrapper {
  margin: 0 auto;
}

.recharts-default-tooltip {
  background-color: white !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
  padding: 8px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.recharts-tooltip-label {
  color: #4a5568 !important;
  font-weight: 500 !important;
}

.recharts-tooltip-item {
  color: #2d3748 !important;
  padding: 4px 0 !important;
}

.recharts-label {
  font-size: 12px !important;
  fill: #4a5568 !important;
}

.recharts-legend-item {
  margin-right: 16px !important;
}

.recharts-legend-item-text {
  color: #4a5568 !important;
}

.recharts-cartesian-axis-tick-value {
  fill: #4a5568 !important;
}

.react-calendar {
  width: 100%;
  max-width: 100%;
  background: white;
  border: none;
  font-family: inherit;
  line-height: 1.125em;
  padding: 1rem;
  border-radius: 0.5rem;
}

/* Dark mode background */
.dark .react-calendar {
  background-color: rgb(30, 41, 59); /* slate-800 */
  color: #fff;
}

/* Navigation buttons */
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
  color: #374151; /* gray-700 */
}

.dark .react-calendar__navigation button {
  color: #e5e7eb; /* gray-200 */
}

.react-calendar__navigation button:disabled {
  background-color: transparent;
  color: #9ca3af; /* gray-400 */
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #ff799d20;
  border-radius: 8px;
}

/* Weekday headers */
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: #4b5563; /* gray-600 */
}

.dark .react-calendar__month-view__weekdays {
  color: #9ca3af; /* gray-400 */
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

/* Weekend days */
.react-calendar__month-view__days__day--weekend {
  color: #ff799d;
}

.dark .react-calendar__month-view__days__day--weekend {
  color: #ff799d;
}

/* Adjacent month days */
.react-calendar__month-view__days__day--neighboringMonth {
  color: #9ca3af; /* gray-400 */
}

.dark .react-calendar__month-view__days__day--neighboringMonth {
  color: #4b5563; /* gray-600 */
}

/* Regular day tiles */
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
  border-radius: 8px;
  color: #374151; /* gray-700 */
  font-weight: 500;
}

.dark .react-calendar__tile {
  color: #e5e7eb; /* gray-200 */
}

.react-calendar__tile:disabled {
  background-color: transparent;
  color: #9ca3af; /* gray-400 */
}

.dark .react-calendar__tile:disabled {
  color: #4b5563; /* gray-600 */
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #ff799d20;
  color: #ff799d;
}

/* Today's date */
.react-calendar__tile--now {
  background: #ff799d20;
  color: #ff799d;
}

.dark .react-calendar__tile--now {
  background: #ff799d40;
  color: #ff799d;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ff799d40;
  color: #ff799d;
}

/* Selected date */
.react-calendar__tile--active {
  background: #ff799d !important;
  color: white !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ff799d !important;
  color: white !important;
}

/* Range selection */
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #ff799d20;
}

/* Dots for days with classes */
.has-classes {
  position: relative;
}

.has-classes::after {
  content: '';
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: #ff799d;
  border-radius: 50%;
}

.dark .has-classes::after {
  background-color: #ff799d;
}

/* Month and year selection buttons */
.react-calendar__year-view__months__month,
.react-calendar__decade-view__years__year,
.react-calendar__century-view__decades__decade {
  color: #374151; /* gray-700 */
}

.dark .react-calendar__year-view__months__month,
.dark .react-calendar__decade-view__years__year,
.dark .react-calendar__century-view__decades__decade {
  color: #e5e7eb; /* gray-200 */
}

/* Navigation arrow buttons hover state */
.react-calendar__navigation button:enabled:hover {
  background-color: #ff799d20;
  color: #ff799d;
}

.dark .react-calendar__navigation button:enabled:hover {
  background-color: #ff799d40;
  color: #ff799d;
}

.font-script {
  font-family: 'Pinyon Script', cursive;
}
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.certificate-name {
  font-family: 'Great Vibes', cursive;
}
.dark-transition {
  @apply transition-colors duration-200;
}

* {
  @apply dark-transition;
}

html.transitioning * {
  transition: none !important;
}
@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Add to your tailwind.css or global styles */
@layer components {
  .schedule-table th {
    @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
  }
  
  .schedule-table td {
    @apply px-6 py-4 whitespace-nowrap;
  }
}